import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ModalItem from "./modalItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect, useCallback } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExportOption from "./ExportOption";
import { useTranslation } from "react-i18next";
import DownloadFiles from "./DownloadFiles";
import CircularProgress from "@mui/material/CircularProgress";
import { getFormat } from "../exportFormats";

const Download = ({ supabase, session, albumIds }) => {
  const { t } = useTranslation();
  const [format, setFormat] = useState("default");
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [albumData, setAlbumData] = useState(null);
  const [albumName, setAlbumName] = useState("");
  const [albumLoading, setAlbumLoading] = useState(true);
  const [showCSV, setShowCSV] = useState(false);

  useEffect(() => {
    const showCSVValue = localStorage.getItem("showCSV");
    setShowCSV(showCSVValue === "true");
  }, []);

  useEffect(() => {
    localStorage.setItem("showCSV", showCSV ? "true" : "false");
  }, [showCSV]);

  useEffect(() => {
    const lastFormat = localStorage.getItem("lastFormat");
    if (lastFormat) {
      setFormat(lastFormat);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("lastFormat", format);
  }, [format]);

  const handleDropdownChange = (index, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleChange = (event) => {
    setFormat(event.target.value);
  };

  function filtrarObjetoConOptions(objeto) {
    const resultado = [];

    for (const clave in objeto) {
      if (
        objeto.hasOwnProperty(clave) &&
        objeto[clave].hasOwnProperty("options")
      ) {
        resultado.push({
          name: clave,
          ...objeto[clave],
        });
      }
    }
    return resultado;
  }

  function applyFormatToArray(arr, formatObj) {
    for (let i = 0; i < arr.length; i++) {
      for (let prop in arr[i]) {
        if (typeof arr[i][prop] === "string") {
          arr[i][prop] = arr[i][prop].replace(/\\'/g, "'").replace(/"/g, "'");
        } else if (Array.isArray(arr[i][prop])) {
          for (let j = arr[i][prop].length - 1; j >= 0; j--) {
            if (arr[i][prop][j].length === 0) {
              arr[i][prop].splice(j, 1);
            } else {
              arr[i][prop][j] = arr[i][prop][j]
                .replace(/\\'/g, "'")
                .replace(/^,|,$/g, "");
            }
          }
        }
      }
    }

    const formattedArr = arr.map((obj) => {
      const formattedObj = {};
      Object.keys(formatObj).forEach((key) => {
        formattedObj[key] = obj.hasOwnProperty(formatObj[key]?.value)
          ? obj[formatObj[key]?.value]
          : formatObj[key]?.value?.trim();
      });
      return formattedObj;
    });
    return formattedArr;
  }

  const arrayToCsv = function (data, semicolon) {
    let separator = semicolon ? ";" : ",";
    const csvRows = [];
    const headers = Object.keys(data[0]);
    let formattedHeaders = headers.map((header) =>
      header.replace(/º/g, " ").replace(/\$/g, "-").replace(/-USD/g, "$USD")
    );
    csvRows.push(formattedHeaders.join(separator));

    for (const row of data) {
      const values = formattedHeaders.map((header) => {
        const val =
          row[header.replace(/ /g, "º").replace(/-/g, "$")] ||
          row[header] ||
          "";
        return semicolon ? val : `"${val}"`;
      });
      csvRows.push(values.join(separator));
    }
    return csvRows.join("\n");
  };

  function descargarCSV(csv, nombreArchivo) {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const enlace = document.createElement("a");
    enlace.href = url;
    enlace.download = nombreArchivo;
    enlace.hidden = true;
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    const getAlbumData = async () => {
      try {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const exportName = `multi_export_${timestamp}`;
        let allCsvs = [];
        for (const albumId of albumIds) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/export`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${supabaseSession.access_token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                album_id: albumId,
                sub_id:
                  session?.subStatus?.[session?.subStatus?.length - 1]?.id ||
                  "no_sub",
                format: format ? format : "default",
              }),
            }
          );
          let thisJson = await response.json();
          if (albumIds.length === 1) {
            return thisJson;
          }
          allCsvs = [...allCsvs, ...thisJson?.csv];
          await new Promise((resolve) => setTimeout(resolve, 200));
        }
        return { csv: allCsvs, name: exportName };
      } catch (err) {
        return { csv: null, name: null };
      }
    };

    if (!albumData) {
      getAlbumData().then(({ csv, name }) => {
        setAlbumData(csv);
        setAlbumName(name);
        setAlbumLoading(false);
      });
    }
  }, [albumData, albumIds, format, session, supabase]);

  const addMetadata = async (
    file,
    title,
    description,
    keywords,
    index,
    total,
    addKeywordsOnly
  ) => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("keywords", keywords);
      formData.append("ownerId", session.user.id);
      formData.append("index", index);
      formData.append("total", total);
      formData.append("addKeywordsOnly", addKeywordsOnly ? "true" : "false");

      const iterativeWait = (iterations) =>
        new Promise((resolve) => setTimeout(resolve, iterations * 3000));

      for (let retries = 0; retries < 6; retries++) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/metadata`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${supabaseSession.access_token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            const blob = await response.blob();

            const newFile = new File([blob], file.name, { type: blob.type });

            if (newFile.size >= 0.99 * file.size) {
              return newFile;
            }
          }

          await iterativeWait(retries + 1);
        } catch (err) {
          await iterativeWait(retries + 1);
        }
      }
      return null;
    } catch (err) {
      return null;
    }
  };

  const handleExport = useCallback(async () => {
    if (albumData) {
      const defaultFormat = getFormat("default", selectedValues);
      const selectedFormat = getFormat(format, selectedValues);
      descargarCSV(
        arrayToCsv(
          applyFormatToArray(
            albumData,
            selectedFormat?.fields || defaultFormat?.fields
          ),
          selectedFormat?.options?.semicolon || false
        ),
        `album_${albumName}_${format}`
      );
    }
  }, [albumData, albumName, format, selectedValues]);

  useEffect(() => {
    const selectedFormat = getFormat(format, selectedValues);
    const getOptions = filtrarObjetoConOptions(selectedFormat?.fields);
    getOptions.forEach((option, index) => {
      handleDropdownChange(index, option?.options[0]?.value);
    });
    setOptions(getOptions);
  }, [format]);

  useEffect(() => {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      setIsMobile(true);
    }
  }, [t]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        height: { xs: "90vh", lg: "40rem" },
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            color: "#FCFCFC",
          }}
        >
          {t("export.export")}
        </Typography>
      </Box>
      <div style={{ display: "flex", padding: "1rem" }}>
        <Button
          variant={!showCSV ? "contained" : "outlined"}
          onClick={() => {
            setShowCSV(false);
          }}
          style={{
            marginRight: 8,
            opacity: !showCSV ? 1 : 0.5,
            backgroundColor: !showCSV ? "#f11d64" : "",
            borderColor: !showCSV ? "#f11d64" : "",
            color: !showCSV ? "white" : "",
            fontWeight: "bold",
          }}
        >
          {t("export.metadata")}
        </Button>
        <Button
          variant={showCSV ? "contained" : "outlined"}
          onClick={() => {
            setShowCSV(true);
          }}
          style={{
            opacity: showCSV ? 1 : 0.5,
            backgroundColor: showCSV ? "#f11d64" : "",
            borderColor: showCSV ? "#f11d64" : "",
            color: showCSV ? "white" : "",
            fontWeight: "bold",
          }}
        >
          {t("export.csv")}
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          mt: "1rem",
        }}
      >
        {showCSV ? (
          <>
            <ModalItem
              title={t("export.settings")}
              primaryContent={t("export.format")}
              dark={true}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  backgroundColor: "#2E0809",
                  borderRadius: "2rem",
                  "&:hover": {
                    border: "none !important",
                  },
                }}
                size="large"
              >
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={format}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={isMobile}
                  sx={{
                    color: "#f11d64",
                    fontWeight: "bold",
                    outline: "none !important",
                    fontSize: "0.89rem",
                    "&:hover": {
                      border: "none",
                    },
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                >
                  <MenuItem value={"default"} selected>
                    Default
                  </MenuItem>
                  <MenuItem value={"adobe_stock"}>Adobe Stock</MenuItem>
                  <MenuItem value={"blackbox"}>BlackBox</MenuItem>
                  <MenuItem value={"dreamstime"}>Dreamstime (Image)</MenuItem>
                  <MenuItem value={"dreamstime_video"}>
                    Dreamstime (Video)
                  </MenuItem>
                  <MenuItem value={"envato"}>Envato</MenuItem>
                  <MenuItem value={"freepik"}>Freepik</MenuItem>
                  <MenuItem value={"getty_images"}>
                    iStock / Getty Images
                  </MenuItem>
                  <MenuItem value={"keycut_stock"}>KEYCUTstock</MenuItem>
                  <MenuItem value={"microstockplus"}>MicrostockPlus</MenuItem>
                  <MenuItem value={"motion_array"}>Motion Array</MenuItem>
                  <MenuItem value={"motionelements"}>
                    MotionElements (Image)
                  </MenuItem>
                  <MenuItem value={"motionelements_video"}>
                    MotionElements (Video)
                  </MenuItem>
                  <MenuItem value={"pixta"}>Pixta</MenuItem>
                  <MenuItem value={"pond5"}>Pond5</MenuItem>
                  <MenuItem value={"shutterstock"}>Shutterstock</MenuItem>
                  <MenuItem value={"stocksubmitter"}>StockSubmitter</MenuItem>
                  <MenuItem value={"story_blocks"}>Storyblocks</MenuItem>
                  <MenuItem value={"vecteezy"}>Vecteezy</MenuItem>
                  <MenuItem value={"xpiks"}>Xpiks</MenuItem>
                  <MenuItem value={"yayimages"}>YAY Images</MenuItem>
                  <MenuItem value={"rf123"}>123RF</MenuItem>
                </Select>
              </FormControl>
            </ModalItem>

            {options.length !== 0 &&
              options.map((field, index) => (
                <ModalItem
                  primaryContent={field?.name
                    .replace(/º/g, " ")
                    .replace(/\$/g, "-")
                    .replace(/-USD/g, "$USD")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  dark={true}
                  key={index}
                >
                  <ExportOption
                    selectedValues={selectedValues}
                    index={index}
                    options={options}
                    field={field}
                    handleDropdownChange={handleDropdownChange}
                  />
                </ModalItem>
              ))}
            <Box sx={{ height: "1rem" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "sticky",
                bottom: "1rem",
                zIndex: 5,
                boxShadow:
                  "inset  0px 1.5rem 0px 2rem #121216, 0px 1.5rem 2rem 3rem #121216",
                mt: "auto",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={
                  albumLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FileDownloadIcon />
                  )
                }
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => handleExport()}
                disabled={albumLoading || isMobile}
              >
                {!albumLoading ? t("export.confirm") : ""}
              </Button>
            </Box>
          </>
        ) : (
          <DownloadFiles
            albumData={albumData}
            albumName={albumName}
            albumLoading={albumLoading}
            addMetadata={addMetadata}
            isMobile={isMobile}
          />
        )}
      </Box>
    </Box>
  );
};
export default Download;
