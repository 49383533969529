import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

function LandingVideo() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundRepeat: "repeat",
        backgroundPosition: "top, center",
        backgroundSize: "cover",
        borderTop: "1px solid #dcdcdc",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "5rem", md: "3rem" },
          py: { xs: 0, sm: 10 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "flex-start",
            alignItems: "center",
            maxWidth: "100rem",
            background: "url(./assets/landing/circle.svg) no-repeat #df3250",
            backgroundPosition: "center",
            backgroundSize: "58%",
            p: { xs: 5, sm: 5, md: 5, lg: 10, xl: 12 },
            mx: { xs: 0, sm: 5, md: 5, lg: 10, xl: 15 },
            transition: "all 1s",
            borderRadius: { xs: "0", sm: "2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: { xs: "column-reverse", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: { xs: "transparent", md: "rgba(0,0,0,0.3)" },
              p: 1,
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "1rem",
                flexDirection: { xs: "row", md: "row" },
                justifyContent: "center",
                pr: { lg: 4 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StarRoundedIcon
                    color="warning"
                    sx={{
                      fontSize: {
                        xs: "0.6rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.2rem",
                      },
                    }}
                  />
                  <StarRoundedIcon
                    color="warning"
                    sx={{
                      fontSize: {
                        xs: "0.6rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.2rem",
                      },
                    }}
                  />
                  <StarRoundedIcon
                    color="warning"
                    sx={{
                      fontSize: {
                        xs: "0.6rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.2rem",
                      },
                    }}
                  />
                  <StarRoundedIcon
                    color="warning"
                    sx={{
                      fontSize: {
                        xs: "0.6rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.2rem",
                      },
                    }}
                  />
                  <StarRoundedIcon
                    color="warning"
                    sx={{
                      fontSize: {
                        xs: "0.6rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.2rem",
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: {
                      xs: "0.6rem",
                      sm: "1rem",
                      md: "1.2rem",
                      lg: "1.2rem",
                    },
                  }}
                >
                  {t("landing.totalUsers")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <CheckRoundedIcon
                  color="success"
                  sx={{
                    fontSize: {
                      xs: "0.6rem",
                      sm: "1rem",
                      md: "1.2rem",
                      lg: "1.2rem",
                    },
                  }}
                />

                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: {
                      xs: "0.6rem",
                      sm: "1rem",
                      md: "1.2rem",
                      lg: "1.2rem",
                    },
                  }}
                >
                  {t("landing.totalPhotos")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "3.5rem",
              }}
            >
              <Link
                href="https://www.producthunt.com/posts/keyword-camera?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-keyword&#0045;camera"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=392033&theme=dark"
                  alt="Keyword&#0032;Camera - Turn&#0032;your&#0032;photos&#0032;into&#0032;title&#0044;&#0032;description&#0032;&#0038;&#0032;keywords&#0032;using&#0032;AI | Product Hunt"
                  style={{
                    width: "250px",
                    height: "54px",
                  }}
                />
              </Link>
            </Box>
          </Box>

          {window.innerWidth > 768 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100%",
                minWidth: "100%",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: "2rem",
                  width: "100%",
                  bgcolor: "rgba(253, 248, 244, 0.85)",
                  borderRadius: "1rem 1rem 0 0",
                  display: "flex",
                  gap: "0.3rem",
                  alignItems: "center",
                  backdropFilter: "blur(5px)",
                  pl: 2,
                  borderBottom: "1px solid #cbc6c2",
                }}
              >
                <CircleIcon htmlColor="#ff5c56" fontSize="1rem" />
                <CircleIcon htmlColor="#ffb92f" fontSize="1rem" />
                <CircleIcon htmlColor="#26c83f" fontSize="1rem" />
              </Box>

              <video
                src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/assets/demo.mp4`}
                autoPlay
                loop
                muted
                controls={false}
                preload="auto"
                type="video/mp4"
                poster="./assets/landing/demo-poster.webp"
                style={{
                  width: "100%",
                  borderRadius: "0 0 1rem 1rem",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
    </Box>
  );
}

export default LandingVideo;
