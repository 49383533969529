import { createContext, useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/helper/supabaseClient";
import { useNavigate } from "react-router-dom";
import { blockedTerms } from "../blockedTerms";

const countryCodesEU = [
  "de",
  "fr",
  "it",
  "es",
  "pt",
  "nl",
  "pl",
  "at",
  "be",
  "cy",
  "ee",
  "fi",
  "gr",
  "ie",
  "lv",
  "lt",
  "lu",
  "mt",
  "sk",
  "si",
  "sm",
  "hr",
  "va",
  "mc",
  "ad",
  "li",
  "ch",
  "no",
  "se",
  "dk",
  "is",
  "gb",
  "uk",
  "bg",
  "cz",
  "hu",
  "ro",
  "rs",
  "me",
  "al",
  "ba",
  "mk",
  "md",
  "ua",
  "by",
  "ru",
];

export const SessionContext = createContext({});

const SessionContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [stashedUser, setStashedUser] = useState(null);
  const [userChecked, setUserChecked] = useState(false);
  const [subStatus, setSubStatus] = useState(null);
  const [planCreditsUsed, setPlanCreditsUsed] = useState(undefined);
  const [bundleCreditsUsed, setBundleCreditsUsed] = useState(undefined);
  const [bundleCreditsAllowed, setBundleCreditsAllowed] = useState(undefined);
  const [bundleCreditsAllowedTotal, setBundleCreditsAllowedTotal] =
    useState(undefined);
  const [showPopup, setShowPopup] = useState(false);
  const [planEmpty, setPlanEmpty] = useState(false);
  const [profitWellStarted, setProfitWellStarted] = useState(false);
  const [checkStorageFromTrends, setCheckStorageFromTrends] = useState(false);
  const [openWindow, setOpenWindow] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState("USD");
  const [maxBatchSize, setMaxBatchSize] = useState(500);
  const [maxImageSize, setMaxImageSize] = useState(40);
  const [maxVideoSize, setMaxVideoSize] = useState(500);
  const [enableExport, setEnableExport] = useState(false);

  useEffect(() => {
    if (
      subStatus?.[subStatus?.length - 1]?.active ||
      bundleCreditsAllowedTotal > 10
    ) {
      setEnableExport(true);
    }
  }, [subStatus, bundleCreditsAllowedTotal]);

  useEffect(() => {
    if (bundleCreditsAllowedTotal >= 100000) {
      setMaxBatchSize(1500);
      setMaxImageSize(100);
      setMaxVideoSize(2000);
    } else if (bundleCreditsAllowedTotal >= 10000) {
      setMaxBatchSize(1000);
      setMaxImageSize(70);
      setMaxVideoSize(1000);
    }
  }, [bundleCreditsAllowedTotal]);

  useEffect(() => {
    const planMaxBatch = Number(
      subStatus?.[subStatus?.length - 1]?.plan?.metadata?.batch
    );

    setMaxBatchSize(Math.max(maxBatchSize, planMaxBatch || 0));

    const planMaxImage = Number(
      subStatus?.[subStatus?.length - 1]?.plan?.metadata?.size
    );

    setMaxImageSize(Math.max(maxImageSize, planMaxImage || 0));

    const planMaxVideo = Number(
      subStatus?.[subStatus?.length - 1]?.plan?.metadata?.vsize
    );

    setMaxVideoSize(Math.max(maxVideoSize, planMaxVideo || 0));
  }, [subStatus, maxBatchSize, maxImageSize, maxVideoSize]);

  const checkUser = useCallback(
    async (customPath = undefined) => {
      try {
        const currSession = await supabase?.auth?.getSession();
        const currUser = currSession?.data?.session?.user;

        if (sessionStorage.getItem("active")) {
          if (currUser) {
            if (stashedUser) {
              navigate(customPath || "/upload", {
                state: { closeDrawer: true },
              });
            }

            const subscriptionActive =
              sessionStorage.getItem("subscriptionActive");

            if (subscriptionActive !== "false") {
              const {
                data: { session: supabaseSession },
              } = await supabase.auth.getSession();

              const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/supabase/subscriptions`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${supabaseSession.access_token}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              const { data: subscriptionData, error } = await response.json();

              if (!error) {
                if (
                  subscriptionData?.length > 0 &&
                  subscriptionData[subscriptionData.length - 1].active &&
                  new Date(
                    subscriptionData[subscriptionData.length - 1].period_end
                  ).getTime() > Date.now()
                ) {
                  let subDataCopy = [...subscriptionData];
                  subDataCopy[subDataCopy?.length - 1].plan.metadata.size =
                    Math.max(
                      subDataCopy[subDataCopy?.length - 1].plan.metadata.size ||
                        0,
                      40
                    );
                  subDataCopy[subDataCopy?.length - 1].plan.metadata.vsize =
                    Math.max(
                      subDataCopy[subDataCopy?.length - 1].plan.metadata
                        .vsize || 0,
                      500
                    );
                  setSubStatus(subDataCopy);
                }
              }
            }
          }
          setStashedUser(null);
          setUser(currUser ?? null);
        } else {
          setStashedUser(currUser ?? null);
          setUser(null);
        }
      } catch (e) {}

      setUserChecked(true);
    },
    [supabase, stashedUser, navigate]
  );

  const login = async (providerName) => {
    sessionStorage.setItem("active", true);
    supabase.auth.signInWithOAuth({
      provider: providerName,
    });
  };

  const loginWithEmail = async (email, password, captchaToken) => {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
      options: { captchaToken },
    });
    if (!error) {
      sessionStorage.setItem("active", true);
      checkUser();
      if (!stashedUser) {
        navigate("/upload", { state: { closeDrawer: true } });
      }
    }
    return error;
  };

  const signUpWithEmail = async (email, password, captchaToken) => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: { captchaToken },
    });
    if (
      data?.user &&
      data?.user?.identities &&
      data?.user?.identities?.length === 0
    ) {
      return {
        name: "EmailInUseError",
        message: "User already exists",
      };
    }
    if (!error) {
      sessionStorage.setItem("active", true);
    }

    return error;
  };

  const forgotPassword = async (email, captchaToken) => {
    const { error } = await supabase.auth.signInWithOtp({
      email: email,
      options: { captchaToken },
    });
    if (!error) {
      localStorage.setItem("forgotPassword", true);
    }
    return error;
  };

  const changePassword = async (email, password) => {
    const { error } = await supabase.auth.updateUser({
      email: email,
      password: password,
    });
    return error;
  };

  const logout = async () => {
    await supabase.auth.signOut();
    setShowPopup(false);
  };

  const downloadImage = async (id, path) => {
    const currentTime = Date.now();
    const imageDownloadsTimestamp = localStorage.getItem("uploadReset");
    const imageDownloads =
      parseInt(localStorage.getItem("titlesAndDescriptions")) || 0;
    const timeElapsed = currentTime - imageDownloadsTimestamp;

    if (timeElapsed > 60000) {
      localStorage.setItem("titlesAndDescriptions", 0);
      localStorage.setItem("uploadReset", currentTime);
    }

    if (imageDownloads >= 1600) {
      return null;
    }

    const { data, error } = await supabase.storage
      .from("images")
      .download(`${id}/${path}`);
    if (error) {
      return null;
    }

    localStorage.setItem("titlesAndDescriptions", imageDownloads + 1);
    return data;
  };

  const getSubscriptions = async () => {
    try {
      if (user) {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/supabase/subscriptions`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { data: subscriptionData, error } = await response.json();
        if (!error) {
          return subscriptionData;
        }
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const handlePayment = async (
    price_id,
    credits_count,
    multiple,
    coupon_id = undefined
  ) => {
    try {
      if (!user && !stashedUser) {
        throw new Error("User not found");
      }

      if (
        !price_id ||
        !credits_count ||
        !multiple ||
        credits_count > 100000 ||
        multiple > 99
      ) {
        throw new Error("Invalid parameters");
      }

      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/checkout-session`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerEmail: user?.email || stashedUser?.email,
            priceId: price_id,
            quantity: multiple,
            creditAmount: credits_count,
            cancelPage: window.location.pathname,
            couponId: coupon_id,
            rewardfulId: window?.Rewardful?.referral || undefined,
          }),
        }
      );

      const { data, error } = await response.json();

      if (!error && data?.url) {
        window.location.href = data.url;
      }
    } catch (e) {}
  };

  const loadUserFromStash = async (customPath = undefined) => {
    sessionStorage.setItem("active", true);
    checkUser(customPath);
  };

  const getPlanCreditsUsed = (generations) => {
    let credits = 0;
    generations?.forEach((gen) => {
      if (gen?.bundle_used !== true) {
        credits += gen?.credits_used;
      }
    });
    return credits;
  };

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event) => {
      switch (event) {
        case "SIGNED_OUT":
          setUser(null);
          setProfitWellStarted(false);
          navigate("/", { state: { closeDrawer: true } });
          break;
        default:
          break;
      }
    });

    checkUser();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getBundleCredits = async () => {
      try {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/supabase/bundles`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const { data, error } = await response.json();

        if (!error) {
          let bundleUsed = 0;
          let bundleAllowed = 0;
          let bundleAllowedTotal = 0;
          if (data?.length !== 0) {
            for (let i = 0; i < data?.length; i++) {
              if (data[i]?.active) {
                bundleUsed += data[i]?.amount_used;
                bundleAllowed += data[i]?.bundle?.credits;
              }
              bundleAllowedTotal += data[i]?.bundle?.credits;
            }
          }
          setBundleCreditsUsed(bundleUsed);
          setBundleCreditsAllowed(bundleAllowed);
          setBundleCreditsAllowedTotal(bundleAllowedTotal);
        }
      } catch (e) {}
    };

    const getGenerations = async (subId, periodStart, periodEnd) => {
      try {
        if (user && subId && periodStart && periodEnd) {
          const {
            data: { session: supabaseSession },
          } = await supabase.auth.getSession();

          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/supabase/generations`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${supabaseSession.access_token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                sub_id: subId,
                period_start: periodStart,
                period_end: periodEnd,
              }),
            }
          );

          const { data: generations, error } = await response.json();

          if (!error) {
            setPlanCreditsUsed(getPlanCreditsUsed(generations) || 0);
          }
        }
      } catch (e) {}
    };

    const checkCredits = async () => {
      if (user?.id) {
        await getBundleCredits();
        const subscriptionActive = sessionStorage.getItem("subscriptionActive");
        if (subscriptionActive !== "false") {
          const subscriptions = await getSubscriptions();
          if (subscriptions?.[subscriptions?.length - 1]?.active) {
            await getGenerations(
              subscriptions?.[subscriptions?.length - 1]?.id,
              subscriptions?.[subscriptions?.length - 1]?.period_start,
              subscriptions?.[subscriptions?.length - 1]?.period_end
            );
          } else {
            sessionStorage.setItem("subscriptionActive", "false");
          }
        }
      }
    };

    if (user) {
      checkCredits();
    }
  }, [user]);

  useEffect(() => {
    const checkPopup = async () => {
      let planCreditsAllowed = 0;
      const subscriptionActive = sessionStorage.getItem("subscriptionActive");
      if (subscriptionActive !== "false") {
        const subscriptions = await getSubscriptions();
        if (subscriptions?.[subscriptions?.length - 1]?.active) {
          planCreditsAllowed =
            subscriptions?.[subscriptions?.length - 1]?.plan?.metadata?.credits;
        } else {
          sessionStorage.setItem("subscriptionActive", "false");
        }
      }
      if (
        bundleCreditsAllowed !== undefined &&
        bundleCreditsUsed !== undefined
      ) {
        if (
          planCreditsAllowed - (planCreditsUsed || 0) <= 0 &&
          bundleCreditsAllowed - bundleCreditsUsed <= 0 &&
          window.location.pathname !== "/thanks"
        ) {
          setShowPopup(true);
        } else {
          setShowPopup(false);
        }
        if (planCreditsUsed >= planCreditsAllowed) {
          setPlanEmpty(true);
        } else {
          setPlanEmpty(false);
        }
      }
    };
    if (
      user &&
      bundleCreditsAllowed !== undefined &&
      bundleCreditsUsed !== undefined &&
      planCreditsUsed !== undefined
    ) {
      checkPopup();
    }
  }, [user, bundleCreditsAllowed, bundleCreditsUsed, planCreditsUsed]);

  useEffect(() => {
    if (user !== null) {
      if (localStorage.getItem("goToCheckout")) {
        const { price_id, credits_count, multiple, coupon_id } = JSON.parse(
          localStorage.getItem("goToCheckout")
        );
        handlePayment(price_id, credits_count, multiple, coupon_id);
      } else if (localStorage.getItem("openCheckout")) {
        setOpenWindow("more-photos");
      } else if (localStorage.getItem("forgotPassword")) {
        setOpenWindow("my-account");
      }
      localStorage.removeItem("goToCheckout");
      localStorage.removeItem("openCheckout");
      localStorage.removeItem("forgotPassword");
    }
    if (checkStorageFromTrends) {
      setCheckStorageFromTrends(false);
    }
  }, [user, checkStorageFromTrends]);

  useEffect(() => {
    if (
      user?.email &&
      blockedTerms.some((term) => user?.email?.split("@")?.[1]?.includes(term))
    ) {
      logout();
    }
  }, [user]);

  useEffect(() => {
    const currency = localStorage.getItem("currency");
    if (currency) {
      setCurrentCurrency(currency);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      if (
        browserLanguage &&
        countryCodesEU.some((code) => browserLanguage.startsWith(code))
      ) {
        setCurrentCurrency("EUR");
      }
    }
  }, []);

  return (
    <SessionContext.Provider
      value={{
        login,
        loginWithEmail,
        signUpWithEmail,
        forgotPassword,
        changePassword,
        logout,
        user,
        downloadImage,
        subStatus,
        planCreditsUsed,
        setPlanCreditsUsed,
        bundleCreditsAllowed,
        bundleCreditsUsed,
        setBundleCreditsUsed,
        planEmpty,
        setPlanEmpty,
        showPopup,
        profitWellStarted,
        setProfitWellStarted,
        setCheckStorageFromTrends,
        openWindow,
        setOpenWindow,
        stashedUser,
        loadUserFromStash,
        currentCurrency,
        setCurrentCurrency,
        handlePayment,
        maxBatchSize,
        maxImageSize,
        maxVideoSize,
        enableExport,
        bundleCreditsAllowedTotal,
        userChecked,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
