import { Fragment, useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import BundleItem from "./bundleItem";
import { Box } from "@mui/material";
import IframeResizer from "iframe-resizer-react";
import { useTranslation } from "react-i18next";
import CurrencySelect from "./CurrencySelect";
import { SessionContext } from "../context/SessionContext";

export default function PricingContainer({ landingPage = true }) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  const discount =
    Number(process.env.REACT_APP_STRIPE_DEFAULT_PROMO_PERCENT) / 100;

  const smallCreditCount = 1500;
  const smallPriceEUR = 26;
  const smallPriceUSD = 28;

  const mediumCreditCount = 10000;
  const mediumPriceEUR = 110;
  const mediumPriceUSD = 120;

  const largeCreditCount = 100000;
  const largePriceEUR = 780;
  const largePriceUSD = 850;

  const bundles = [
    {
      title:
        "🚴‍♂️ " +
        t("landing.smallUploadCredits", {
          amount: smallCreditCount.toLocaleString(),
        }),
      count: smallCreditCount,
      price:
        session?.currentCurrency === "EUR"
          ? smallPriceEUR * discount
          : smallPriceUSD * discount,
      old_price:
        session?.currentCurrency === "EUR" ? smallPriceEUR : smallPriceUSD,
      price_id:
        session?.currentCurrency === "EUR"
          ? process.env.REACT_APP_1500_PRODUCT_EUR
          : process.env.REACT_APP_1500_PRODUCT_USD,
      coupon_id: process.env.REACT_APP_STRIPE_DEFAULT_PROMO,
      subheader: t("landing.starter"),
      description: [
        t("landing.maxBatch", { batch: "500" }),
        t("landing.maxSize", { size: "40MB" }),
        t("landing.maxVideoSize", { size: "500MB" }),
        t("landing.neverExpires"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: false,
      action: "login",
    },
    {
      title:
        "🚀 " +
        t("landing.mediumUploadCredits", {
          amount: mediumCreditCount.toLocaleString(),
        }),
      count: mediumCreditCount,
      price:
        session?.currentCurrency === "EUR"
          ? mediumPriceEUR * discount
          : mediumPriceUSD * discount,
      old_price:
        session?.currentCurrency === "EUR" ? mediumPriceEUR : mediumPriceUSD,
      price_id:
        session?.currentCurrency === "EUR"
          ? process.env.REACT_APP_10000_PRODUCT_EUR
          : process.env.REACT_APP_10000_PRODUCT_USD,
      coupon_id: process.env.REACT_APP_STRIPE_DEFAULT_PROMO,
      subheader: t("landing.popular"),
      description: [
        t("landing.maxBatch", { batch: "1,000" }),
        t("landing.maxSize", { size: "70MB" }),
        t("landing.maxVideoSize", { size: "1GB" }),
        t("landing.neverExpires"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: true,
      action: "login",
    },
    {
      title:
        "🏭 " +
        t("landing.largeUploadCredits", {
          amount: largeCreditCount.toLocaleString(),
        }),
      count: largeCreditCount,
      price:
        session?.currentCurrency === "EUR"
          ? largePriceEUR * discount
          : largePriceUSD * discount,
      old_price:
        session?.currentCurrency === "EUR" ? largePriceEUR : largePriceUSD,
      price_id:
        session?.currentCurrency === "EUR"
          ? process.env.REACT_APP_100000_PRODUCT_EUR
          : process.env.REACT_APP_100000_PRODUCT_USD,
      coupon_id: process.env.REACT_APP_STRIPE_DEFAULT_PROMO,
      subheader: t("landing.power"),
      description: [
        t("landing.maxBatch", { batch: "1,500" }),
        t("landing.maxSize", { size: "100MB" }),
        t("landing.maxVideoSize", { size: "2GB" }),
        t("landing.neverExpires"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: false,
      action: "login",
    },
  ];

  return (
    <Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pb: 4 }}
        id="packages"
      >
        {landingPage && (
          <>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              gutterBottom
              color={"black"}
              fontWeight={"bold"}
            >
              {t("landing.pricing")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                align="center"
                color="black"
                component="p"
                sx={{ fontWeight: "bold", fontSize: "1.2rem", width: "95%" }}
              >
                {t("landing.noSubscription")}
              </Typography>
            </Box>
          </>
        )}
      </Container>
      <Container
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          {bundles.map((bundleItem) => (
            <BundleItem
              title={bundleItem.title}
              credits_count={bundleItem.count}
              subheader={bundleItem.subheader}
              price={bundleItem.price}
              old_price={bundleItem.old_price}
              price_id={bundleItem.price_id}
              coupon_id={bundleItem.coupon_id}
              description={bundleItem.description}
              buttonText={bundleItem.buttonText}
              highlighted={bundleItem.highlighted}
              action={bundleItem.action}
              key={bundleItem.count}
            />
          ))}
        </Grid>

        <CurrencySelect color="#3535ff" />

        {landingPage && (
          <Box
            sx={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              mt: 4,
              mb: 4,
            }}
          >
            <IframeResizer
              src="https://embed-v2.testimonial.to/t/-No0FBc5KcYSTeBCTPQw?design=left-aligned&fontFamily=Inter&fontColor=000000&bgColor=f5f5f5&borderColor=ABB8C3&starColor=FDCC0D&shadowSize=shadow-none&showBorder=no&showPadding=yes&borderRadius=rounded-lg&borderWidth=border"
              style={{ width: "1px", minWidth: "100%", border: "none" }}
            />
          </Box>
        )}
      </Container>
    </Fragment>
  );
}
