import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { Button } from "@mui/material";
import NotFound from "./NotFound";
import { addCommas } from "../utils";

function Admin({ supabase }) {
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const [list, setList] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const getList = async () => {
    try {
      if (session?.user?.id) {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/list`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          const json = await response.json();
          setList(json);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (session?.user?.id === process.env.REACT_APP_ADMIN_UID) {
      getList();
      setIsAdmin(true);
    }
  }, [session?.user]);

  return isAdmin ? (
    <Box
      sx={{
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
        width: "100%",
        minHeight: "110vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          justifyContent: "left",
          alignItems: "left",
          padding: {
            xs: "5rem 5rem",
          },
          maxWidth: { xs: "90vw", md: "50vw" },
          aspectRatio: { xs: "9 / 16", md: "16 / 9" },
        }}
      >
        <Typography variant="h3" color="initial" fontWeight={"bold"}>
          Stats
        </Typography>
        {list ? (
          <Fragment>
            <Typography variant="h4" color="initial" fontWeight={"bold"}>
              Users
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.usersTotal)}</strong> total users
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.usersToday)}</strong> new users today
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.usersWeek)}</strong> new users this week
              (<strong>{(list?.usersWeek / 7).toFixed(2)} / day</strong>)
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.usersMonth)}</strong> new users this
              month (<strong>{(list?.usersMonth / 30).toFixed(2)} / day</strong>
              )
            </Typography>
            <Typography
              variant="h4"
              color="initial"
              fontWeight={"bold"}
              sx={{ mt: 5 }}
            >
              Purchases
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>
                {addCommas(list?.bundlesToday - list?.usersToday)}
              </strong>{" "}
              new purchases today
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.bundlesWeek - list?.usersWeek)}</strong>{" "}
              new purchases this week (
              <strong>
                {((list?.bundlesWeek - list?.usersWeek) / 7).toFixed(2)} / day
              </strong>
              )
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>
                {addCommas(list?.bundlesMonth - list?.usersMonth)}
              </strong>{" "}
              new purchases this month (
              <strong>
                {((list?.bundlesMonth - list?.usersMonth) / 30).toFixed(2)} /
                day
              </strong>
              )
            </Typography>
            <Typography
              variant="h4"
              color="initial"
              fontWeight={"bold"}
              sx={{ mt: 5 }}
            >
              Albums
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.albumsToday)}</strong> new albums today
            </Typography>
            <Typography variant="h4" color="initial" textAlign={"left"}>
              <strong>{addCommas(list?.albumsError)}</strong> album errors today
            </Typography>
          </Fragment>
        ) : (
          <Typography variant="h4" color="initial" textAlign={"left"}>
            Loading...
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mt: 5, maxWidth: "15rem" }}
        >
          Back to home
        </Button>
      </Box>
    </Box>
  ) : (
    <NotFound />
  );
}

export default Admin;
