import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en-us/en-us.json";
import ar from "./locales/ar/ar.json";
import bn from "./locales/bn/bn.json";
import de from "./locales/de/de.json";
import es from "./locales/es/es.json";
import fr from "./locales/fr/fr.json";
import he from "./locales/he/he.json";
import hi from "./locales/hi/hi.json";
import id from "./locales/id/id.json";
import it from "./locales/it/it.json";
import ja from "./locales/ja/ja.json";
import ko from "./locales/ko/ko.json";
import pl from "./locales/pl/pl.json";
import pt from "./locales/pt-br/pt-br.json";
import ru from "./locales/ru/ru.json";
import sr from "./locales/sr/sr.json";
import tr from "./locales/tr/tr.json";
import uk from "./locales/uk/uk.json";
import vi from "./locales/vi/vi.json";
import zh from "./locales/zh/zh.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: { translation: en },
      ar: { translation: ar },
      bn: { translation: bn },
      de: { translation: de },
      es: { translation: es },
      fr: { translation: fr },
      he: { translation: he },
      hi: { translation: hi },
      id: { translation: id },
      it: { translation: it },
      ja: { translation: ja },
      ko: { translation: ko },
      pl: { translation: pl },
      pt: { translation: pt },
      ru: { translation: ru },
      sr: { translation: sr },
      tr: { translation: tr },
      uk: { translation: uk },
      vi: { translation: vi },
      zh: { translation: zh },
    },
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      caches: ["localStorage", "cookie"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "i18nextLng",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
