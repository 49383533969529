import React, { useState, Fragment, useEffect, useContext } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../context/SessionContext";

export default function AutoRefillMenu({ supabase, handleClose }) {
  const { t, i18n } = useTranslation();
  const session = useContext(SessionContext);

  const [credits, setCredits] = useState(10000);
  const [weeks, setWeeks] = useState(1);
  const [currency, setCurrency] = useState("USD");
  const [customPrice, setCustomPrice] = useState(null);
  const [lastPurchaseDate, setLastPurchaseDate] = useState(null);
  const [nextPurchaseDate, setNextPurchaseDate] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [existingRefillScheduled, setExistingRefillScheduled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [paymentInfoRequired, setPaymentInfoRequired] = useState(undefined);
  const [paymentInfoSetupUrl, setPaymentInfoSetupUrl] = useState(undefined);
  const [existingCredits, setExistingCredits] = useState(undefined);
  const [existingWeeks, setExistingWeeks] = useState(undefined);
  const [existingCurrency, setExistingCurrency] = useState(undefined);
  const [configChanged, setConfigChanged] = useState(false);
  const [paymentInfoString, setPaymentInfoString] = useState(undefined);
  const [createdAt, setCreatedAt] = useState(undefined);

  const discountedPricePer10k = currency === "EUR" ? 44 : 48;
  const normalPricePer10k = currency === "EUR" ? 110 : 120;

  const totalDiscountedPrice = (discountedPricePer10k * credits) / 10000;
  const totalNormalPrice = (normalPricePer10k * credits) / 10000;

  useEffect(() => {
    if (
      existingCredits !== undefined &&
      existingWeeks !== undefined &&
      existingCurrency !== undefined
    ) {
      if (
        credits !== existingCredits ||
        weeks !== existingWeeks ||
        currency !== existingCurrency
      ) {
        setConfigChanged(true);
      } else {
        setConfigChanged(false);
      }
    }
  }, [
    credits,
    existingCredits,
    weeks,
    existingWeeks,
    currency,
    existingCurrency,
  ]);

  useEffect(() => {
    if (configChanged && credits % 10000 !== 0) {
      setCredits((prev) => Math.round(prev / 10000) * 10000);
    }
  }, [configChanged, credits]);

  const getPaymentInfoSession = async (user_email) => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/payment-setup`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerEmail: user_email,
          }),
        }
      );

      const { data, error } = await response.json();

      if (!error && data?.url) {
        setPaymentInfoSetupUrl(data.url);
      }
    } catch (e) {}
  };

  const checkPaymentInfo = async (user_email) => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/payment-info`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerEmail: user_email,
          }),
        }
      );

      const { data, error } = await response.json();

      if (!error) {
        if (data) {
          setPaymentInfoString(data);
          setPaymentInfoRequired(false);
        } else {
          setPaymentInfoString(undefined);
          setPaymentInfoRequired(true);
          getPaymentInfoSession(user_email);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (session?.user?.email) {
      checkPaymentInfo(session.user.email);
    }
  }, [session?.user?.email]);

  const checkRefill = async () => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/supabase/refills`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { data, error } = await response.json();

      if (!error) {
        if (data?.length > 0) {
          setExistingRefillScheduled(true);
          setCredits(data[0].credits);
          setExistingCredits(data[0].credits);
          setWeeks(data[0].weeks);
          setExistingWeeks(data[0].weeks);
          setCurrency(data[0].currency);
          setExistingCurrency(data[0].currency);
          setCreatedAt(
            data[0].created_at ? new Date(data[0].created_at) : undefined
          );
          setLastPurchaseDate(
            data[0].last_refill ? new Date(data[0].last_refill) : undefined
          );
          setCustomPrice(data[0].price / 100);
        }
        setDataLoading(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (session?.user?.id) {
      checkRefill();
    }
  }, [session?.user?.id]);

  useEffect(() => {
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const today = new Date();
    let nextPurchase = new Date(today.getTime() + oneWeek + oneDay);
    if (!configChanged) {
      if (lastPurchaseDate) {
        nextPurchase = new Date(
          lastPurchaseDate.getTime() + weeks * oneWeek + oneDay
        );
      } else if (createdAt) {
        nextPurchase = new Date(createdAt.getTime() + oneWeek + oneDay);
      }
    }
    if (nextPurchase < today) {
      setNextPurchaseDate(today);
    } else {
      setNextPurchaseDate(nextPurchase);
    }
  }, [weeks, lastPurchaseDate, configChanged, createdAt]);

  const createOrUpdateRefill = async (
    user_email,
    credits,
    weeks,
    price,
    currency
  ) => {
    try {
      if (
        !user_email ||
        !credits ||
        !weeks ||
        !price ||
        !currency ||
        weeks < 1 ||
        isNaN(credits) ||
        isNaN(price)
      ) {
        throw new Error("Invalid parameters");
      }

      if (currency === "USD") {
        if (price / credits !== 0.48) {
          throw new Error("Price per credit ratio incorrect");
        }
      } else if (currency === "EUR") {
        if (price / credits !== 0.44) {
          throw new Error("Price per credit ratio incorrect");
        }
      } else {
        throw new Error("Invalid currency");
      }

      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/refill`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userEmail: user_email,
            credits,
            weeks,
            price,
            currency,
          }),
        }
      );

      const { error } = await response.json();

      return !error;
    } catch (e) {
      return false;
    }
  };

  const handleSchedule = () => {
    setLoading(true);
    createOrUpdateRefill(
      session?.user?.email,
      credits,
      weeks,
      totalDiscountedPrice * 100,
      currency
    ).then((success) => {
      setLoading(false);
      if (success) {
        setExistingRefillScheduled(true);
        setSuccessMessage(t("plan.scheduleSuccess"));
      } else {
        setSuccessMessage(t("plan.scheduleError"));
      }
    });
  };

  const clearRefill = async () => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/refill`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { error } = await response.json();

      return !error;
    } catch (e) {
      return false;
    }
  };

  const handleCancel = () => {
    setLoading(true);
    clearRefill().then((success) => {
      setLoading(false);
      if (success) {
        setExistingRefillScheduled(false);
        setSuccessMessage(t("plan.cancelSuccess"));
      } else {
        setSuccessMessage(t("plan.cancelError"));
      }
    });
  };

  return (
    <Fragment>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "1rem",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            maxWidth: "600px",
            width: "100%",
            borderRadius: "2rem",
            border: "3px solid lightgray",
          }}
        >
          {dataLoading || paymentInfoRequired === undefined ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: "20rem",
                mt: "5rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Fragment>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  {t("plan.payment")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "monospace",
                      color: "gray",
                    }}
                  >
                    {paymentInfoString || t("plan.paymentInfoRequired")}
                  </Typography>
                  {paymentInfoRequired === true ? (
                    <Button
                      variant="contained"
                      color="primary"
                      href={paymentInfoSetupUrl}
                      sx={{
                        borderRadius: "0.5rem",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {t("plan.addPaymentInfo")}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_STRIPE_URL}/p/login/3cs14t9l4deC7tu000`,
                          "_blank"
                        );
                      }}
                      sx={{
                        borderRadius: "0.5rem",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {t("plan.editPaymentInfo")}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  {t("plan.quantity")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    onClick={() =>
                      setCredits((prev) => Math.max(prev - 10000, 10000))
                    }
                    disabled={credits <= 10000 || successMessage !== undefined}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="h6">
                    {t("plan.uploadCredits", {
                      credits: credits.toLocaleString(),
                    })}
                  </Typography>
                  <IconButton
                    onClick={() =>
                      setCredits((prev) => Math.min(prev + 10000, 990000))
                    }
                    disabled={credits >= 990000 || successMessage !== undefined}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  {t("plan.interval")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    onClick={() => setWeeks((prev) => Math.max(prev - 1, 1))}
                    disabled={weeks <= 1 || successMessage !== undefined}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="h6">
                    {weeks === 1
                      ? t("plan.intervalSingular")
                      : t("plan.intervalPlural", { weeks })}
                  </Typography>
                  <IconButton
                    onClick={() => setWeeks((prev) => Math.min(prev + 1, 99))}
                    disabled={weeks >= 99 || successMessage !== undefined}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  {t("plan.currency")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant={currency === "USD" ? "contained" : "outlined"}
                    onClick={() => {
                      setCurrency("USD");
                    }}
                    disabled={successMessage !== undefined}
                    style={{
                      marginRight: 8,
                      opacity: currency === "USD" ? 1 : 0.5,
                      backgroundColor: currency === "USD" ? "#f11d64" : "",
                      borderColor: currency === "USD" ? "#f11d64" : "",
                      color: currency === "USD" ? "white" : "",
                      fontWeight: "bold",
                    }}
                  >
                    USD
                  </Button>
                  <Button
                    variant={currency === "EUR" ? "contained" : "outlined"}
                    onClick={() => {
                      setCurrency("EUR");
                    }}
                    disabled={successMessage !== undefined}
                    style={{
                      opacity: currency === "EUR" ? 1 : 0.5,
                      backgroundColor: currency === "EUR" ? "#f11d64" : "",
                      borderColor: currency === "EUR" ? "#f11d64" : "",
                      color: currency === "EUR" ? "white" : "",
                      fontWeight: "bold",
                    }}
                  >
                    EUR
                  </Button>
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                {customPrice && !configChanged ? (
                  <Typography variant="h6" fontWeight="bold" fontSize="3rem">
                    {currency === "EUR" ? "€" : "$"}
                    {customPrice.toLocaleString()}
                  </Typography>
                ) : (
                  <Typography variant="h6" fontWeight="bold" fontSize="3rem">
                    {currency === "EUR" ? "€" : "$"}
                    {totalDiscountedPrice.toLocaleString()}
                    <Typography
                      component="span"
                      variant="body2"
                      fontSize="2rem"
                      sx={{
                        textDecoration: "line-through",
                        color: "gray",
                        ml: 2,
                      }}
                    >
                      {currency === "EUR" ? "€" : "$"}
                      {totalNormalPrice.toLocaleString()}
                    </Typography>
                  </Typography>
                )}
                <Typography variant="body2" fontSize="1.1rem">
                  <strong>
                    {existingRefillScheduled
                      ? t("plan.nextPurchase")
                      : t("plan.firstRefill")}
                  </strong>{" "}
                  {nextPurchaseDate?.toLocaleDateString(i18n.language, {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Typography>
                <Typography variant="body2" fontSize="1.1rem">
                  <strong>{t("plan.repeatingEvery")}</strong>{" "}
                  {weeks === 1
                    ? t("plan.repeatingEverySingular")
                    : t("plan.repeatingEveryPlural", { weeks })}
                </Typography>
              </Box>
              {successMessage ? (
                <>
                  <Typography
                    variant="body1"
                    fontSize="1.1rem"
                    fontWeight="bold"
                    color="primary"
                    sx={{ textAlign: "center" }}
                  >
                    {successMessage}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClose}
                    sx={{
                      mt: 2,
                      borderRadius: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {t("plan.close")}
                  </Button>
                </>
              ) : existingRefillScheduled ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSchedule}
                    disabled={loading || !configChanged || paymentInfoRequired}
                    sx={{
                      borderRadius: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      t("plan.update")
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleCancel}
                    disabled={loading}
                    sx={{
                      borderRadius: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      t("plan.cancel")
                    )}
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSchedule}
                  disabled={loading || paymentInfoRequired}
                  sx={{
                    borderRadius: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    t("plan.schedule", {
                      date: nextPurchaseDate?.toLocaleDateString(
                        i18n.language,
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      ),
                    })
                  )}
                </Button>
              )}{" "}
            </Fragment>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
