import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import AlbumsTable from "../components/AlbumsTable";
import LogIn from "./LogIn";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";

function Albums({ supabase }) {
  const itemsPerPage = 20;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const [userAlbums, setUserAlbums] = useState([]);
  const [filteredUserAlbums, setFilteredUserAlbums] = useState([]);
  const [albumsLoading, setAlbumsLoading] = useState(true);
  const [pagesLoaded, setPagesLoaded] = useState(5);
  const [userAlbumsCount, setUserAlbumsCount] = useState(0);
  const [blobImages, setBlobImages] = useState({});

  useEffect(() => {
    async function downloadAndCreateBlobURLs() {
      let updatedBlobImages = { ...blobImages };
      for (let i = 0; i < userAlbums?.length; i++) {
        const albumId = userAlbums?.[i]?.id;
        if (!updatedBlobImages[albumId]) {
          const file = await session.downloadImage(
            session?.user?.id,
            encodeURIComponent(userAlbums?.[i]?.photos?.[0]?.path)
          );
          const blobURL = file ? URL.createObjectURL(file) : null;
          updatedBlobImages[albumId] = blobURL;
          setBlobImages(updatedBlobImages);
        }
      }
    }

    downloadAndCreateBlobURLs();
  }, [userAlbums]);

  useEffect(() => {
    if (userAlbums?.length !== userAlbumsCount) {
      setUserAlbumsCount(userAlbums.length);
    }
  }, [userAlbums, userAlbumsCount]);

  const actions = [
    {
      name: t("albums.exportResults"),
      slug: "album-export",
      multiple: true,
      disabled: false,
      action: function (ids) {
        if (session?.enableExport) {
          navbar.setOpenNavModal(ids);
          navbar.setSelectedNavItem("download");
        } else {
          navbar.setOpenNavModal(true);
          navbar.setSelectedNavItem("more-photos-blocked");
        }
      },
      icon: DownloadIcon,
    },
    {
      name: t("albums.copyLink"),
      slug: "album-copy",
      multiple: false,
      disabled: false,
      action: function (id) {
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_PRIMARY_URL}/result/${id}`
        );
      },
      icon: InsertLinkOutlinedIcon,
    },
    /*{
      name: "Rename (Soon)",
      slug: "album-rename",
      multiple: false,
      disabled: true,
      action: function (album) {
        navbar.setOpenNavModal(true);
        navbar.setSelectedNavItem(this.slug);
      },
      icon: DriveFileRenameOutlineOutlinedIcon,
    },*/
    {
      name: t("albums.delete"),
      slug: "album-delete",
      color: "error",
      multiple: true,
      disabled: false,
      action: function (ids) {
        navbar.setOpenNavModal(ids);
        navbar.setSelectedNavItem(this.slug);
      },
      icon: DeleteOutlineOutlinedIcon,
    },
  ].map((action) => ({ ...action, action: action.action.bind(action) }));

  useEffect(() => {
    const getData = async () => {
      try {
        if (userAlbumsCount >= pagesLoaded * itemsPerPage - 1) {
          return;
        }

        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/supabase/albums-page`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              range_start: userAlbumsCount,
              range_end: pagesLoaded * itemsPerPage - 1,
            }),
          }
        );

        const { data, error } = await response.json();

        if (!error) {
          setUserAlbums((prev) => [...prev, ...data]);
          setFilteredUserAlbums((prev) => [...prev, ...data]);
          setAlbumsLoading(false);
        }
      } catch (e) {}
    };

    if (session?.user?.id) {
      getData();
    }
  }, [session?.user?.id, supabase, navigate, pagesLoaded, userAlbumsCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return session?.user ? (
    <Box
      sx={{
        background: "linear-gradient(to right, #d3cce3, #e9e4f0)",
        backgroundSize: "contain",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          justifyContent: "flex-start",
          alignItems: "center",
          mt: 10,
          padding: {
            xs: "20% 10%",
            sm: "6rem 15%",
            md: "5% 15%",
            lg: "5% 15%",
            xl: "2% 15%",
          },
          width: { xs: "80vw", md: "90vw" },
          aspectRatio: { xs: "9 / 16", md: "16 / 9" },
        }}
      >
        <Typography
          variant="h3"
          color="initial"
          fontWeight="bold"
          sx={{ align: "left", color: "#000", mb: 1 }}
        >
          {t("albums.albums")}
        </Typography>

        <AlbumsTable
          session={session}
          filteredUserAlbums={filteredUserAlbums}
          setFilteredUserAlbums={setFilteredUserAlbums}
          userAlbums={userAlbums}
          navigate={navigate}
          actions={actions}
          albumsLoading={albumsLoading}
          pagesLoaded={pagesLoaded}
          setPagesLoaded={setPagesLoaded}
          blobImages={blobImages}
          itemsPerPage={itemsPerPage}
        />
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
          <strong>{t("albums.disclaimerPrefix")}</strong>{" "}
          {t("albums.disclaimer")}
        </Typography>
      </Box>
    </Box>
  ) : (
    <LogIn customPath={"/albums"} />
  );
}

export default Albums;
